.addTVchannels_wrapper {
  width: 100%;
  min-height: calc(100vh - 175px);
  background: #f9f9f9;
}

.addTVchannels_holder {
  min-height: calc(100vh - 235px);
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px 0;
}

.addTVchannels_holder h2 {
  font-size: 30px;
  font-weight: 700;
  color: #322e2e;
}

.addTVchannels_holder label {
  font-size: 22px;
  color: #322e2e;
  display: block;
  margin: 10px 0;
}

.addTVchannels_holder .select {
  width: 350px;
  height: 45px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  position: relative;
}

.addTVchannels_holder .select.scroll .options {
  overflow-y: scroll;
  height: 397px;
}

.addTVchannels_holder .select.scroll .options::-webkit-scrollbar {
  width: 10px;
}

.addTVchannels_holder .select.scroll .options::-webkit-scrollbar-track {
  background: #f1f1f1;
}

.addTVchannels_holder .select.scroll .options::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 100px;
}

.addTVchannels_holder .select.scroll .options::-webkit-scrollbar-thumb:hover {
  background: #c2c2c2;
}

.addTVchannels_holder .select .top {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 30px;
  color: #322e2e;
  font-size: 20px;
  cursor: pointer;
}

.addTVchannels_holder .select .top .arrows {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #909090;
}

.addTVchannels_holder .select .top .arrows svg {
  margin: -3px 0;
}

.addTVchannels_holder .select .options {
  position: absolute;
  top: 48px;
  left: -1px;
  right: -1px;
  background: #fff;
  border-radius: 2px;
  border-top: 0;
  border: 1px solid #d7d7d7;
  z-index: 99;
}

.addTVchannels_holder .select .options p {
  text-align: left;
  padding: 5px 30px;
  color: #322e2e;
  font-size: 18px;
  cursor: pointer;
  border-bottom: 1px solid #d7d7d7;
  background: #fff;
}

.addTVchannels_holder .select .options p:last-child {
  border: 0;
}

.addTVchannels_holder .select .options p.active {
  background: #d7d7d74f;
}

.addTVchannels_holder .select .options p:hover {
  background: #d7d7d74f;
}

.addTVchannels_holder input[type="text"] {
  width: 350px;
  height: 45px;
  border-radius: 2px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.07);
  background-color: #ffffff;
  border: 1px solid #d7d7d7;
  padding: 0 30px;
  color: #322e2e;
  font-weight: 400;
  font-size: 20px;
}

.addTVchannels_holder .byName_items {
  width: 350px;
  max-height: 480px;
  overflow-y: scroll;
  padding-right: 20px;
  margin: 20px 0 50px 0;
}

.addTVchannels_holder .byName_items::-webkit-scrollbar {
  width: 6px;
}

.addTVchannels_holder .byName_items::-webkit-scrollbar-track {
  background: transparent;
}

.addTVchannels_holder .byName_items::-webkit-scrollbar-thumb {
  background: #c2c2c2;
  border-radius: 100px;
}

.addTVchannels_holder .byName_items::-webkit-scrollbar-thumb:hover {
  background: #c2c2c2;
}

.addTVchannels_holder .byName_items .item {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border-bottom: 1px solid #eaeaea;
  padding: 5px 0;
  position: relative;
}

.addTVchannels_holder .byName_items .item .img {
  width: 40px;
  height: 40px;
  background-size: contain !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.addTVchannels_holder .byName_items .item p {
  font-size: 18px;
  color: #322e2e;
  font-weight: 400;
  margin-left: 20px;
}

.addTVchannels_holder .byName_items .item .checkbox {
  margin-left: auto;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid #909090;
  background: #eee;
  border-radius: 4px;
  font-size: 12px;
  color: #2b9cd9;
  cursor: pointer;
}
