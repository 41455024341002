* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-size: 16px;
  line-height: 1.4;
  background: #fff;
  color: #222;
  font-family: "Roboto Condensed";
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}

button {
  cursor: pointer;
}

button,
textarea,
input,
select {
  outline: none;
}

@media (max-width: 5000px) {
  .container {
    width: 2000px;
    margin: 0 auto;
  }
}

@media (max-width: 2000px) {
  .container {
    width: 95%;
    margin: 0 auto;
  }
}

.content {
  margin-top: 100px;
}

.error-message {
  color: #c00;
  font-size: 16px;
  margin-bottom: 40px;
}

.flex {
  display: flex;
}

.active_nav {
  background: linear-gradient(#00acdf, #00679b) !important;
  color: #fff !important;
}

.title {
  font-size: 3em;
  font-weight: lighter;
}

.min_height {
  min-height: calc(100vh - 200px);
}

.white {
  background: #fff;
}

.grey {
  background: #eee;
}

.light_bg {
  background: #f9f9f9;
}

.blue {
  background: linear-gradient(#00acdf, #00679b) !important;
  color: #fff !important;
}

.hidden {
  display: none;
}

.relative {
  position: relative;
}

.shadow::before {
  content: "";
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  background: linear-gradient(
    90deg,
    #d7d7d7,
    hsla(0, 0%, 89%, 0.91) 9%,
    hsla(0, 0%, 100%, 0)
  );
}

.fixed_shadow {
  position: fixed;
  top: 0;
  height: 100%;
  left: 450px;
  width: 50px;
  background: linear-gradient(
    90deg,
    #d7d7d7,
    hsla(0, 0%, 89%, 0.91) 9%,
    hsla(0, 0%, 100%, 0)
  );
}

.mini_popup {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
}

.mini_popup_holder {
  width: 500px;
  height: 270px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px;
  color: #444;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
  background: #fff;
}

.mini_popup svg {
  font-size: 4em;
}

.mini_popup h2 {
  text-transform: uppercase;
  font-size: 32px;
  color: #444;
}

.mini_popup p {
  text-align: center;
  font-size: 16px;
  margin: 10px 0;
}

.mini_popup button {
  font-size: 16px;
  text-transform: uppercase;
  color: #fff;
  font-weight: normal;
  width: 150px;
  height: 35px;
  border-radius: 4px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.3);
  background: linear-gradient(#00acdf, #00679b);
  border: 0;
}

@media (max-width: 600px) {
  .mini_popup_holder {
    width: 90%;
  }
}

.loading {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.loading > p {
  width: 100%;
  text-align: center;
  font-size: 34px;
  color: #222;
  font-weight: lighter;
  margin-bottom: 20px;
}

.loading > img {
  width: 70px;
  animation: loader 2s linear;
}

@keyframes loader {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

@media (max-width: 1400px) {
  .title {
    font-size: 34px;
  }
}

@media (max-width: 1050px) {
  .title {
    font-size: 28px;
  }
}

@media (max-width: 500px) {
  .title {
    font-size: 24px;
  }
}

.tvchannels_style {
  height: calc(100vh - 150px);
  overflow: hidden;
}

.internet_tv_channels_height {
  overflow: hidden;
  height: calc(100vh - 260px);
}

.jcc {
  justify-content: center !important;
}

.proggres_bar {
  width: 100%;
  height: 5px;
  position: absolute;
  left: 0;
  bottom: 120px;
  background: hsla(5, 5%, 49%, 0.7);
  z-index: 1;
}

.proggres_bar .buffer {
  width: 95%;
  height: 5px;
  background: #00acdf;
}

.loader_wrap {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  z-index: 999;
}

.loader_spin {
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid rgb(92, 18, 92);
  width: 100px;
  height: 100px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* Dropdown */
.dropdown {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 140px;
  height: 40px;
  padding: 0 15px;
  font-size: 13px;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  background: linear-gradient(#00acdf, #00679b);
  position: relative;
  transition: all 0.3s ease-in-out;
  position: relative;
  margin-left: 20px;
}

.dropdown .dropdown_options {
  position: absolute;
  top: 50px;
  left: 0;
  right: 0;
  background: #fff;
  z-index: 5;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}

.dropdown .dropdown_options p {
  padding: 10px;
  font-size: 13px;
  transition: all 0.3s ease-in-out;
  color: #000;
}

.dropdown .dropdown_options p:hover {
  color: #00acdf;
}

.active_drop_option {
  color: #00acdf !important;
}

.dropdown:hover {
  background: linear-gradient(#00acdf, #00679b);
}

.dropdown:hover > p {
  color: #fff;
}

.dropdown:hover > svg {
  color: #fff;
}

.dropdown > svg {
  transition: all 0.3s ease-in-out;
  transform: rotate(0);
}

.channels_stream_wrap
  .channels_stream_wrapper_dropdowns
  .dropdown
  > svg.rotate {
  transform: rotate(180deg) !important;
}

.rotateArrow {
  transform: rotate(-180deg) !important;
}

.nochannel_wrap {
  position: absolute;
  top: 0;
  left: 30px;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 32px;
  padding-top: -50px;
}

.nochannel_wrap > svg {
  font-size: 3em;
  fill: #777;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

.full-width {
  width: 100%;
}

video {
  outline: none !important;
}

.page_loader {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 99;
  display: flex;
  align-items: center;
  justify-content: center;
}

.page_loader svg {
  transition: all 0.5s ease-in-out;
  animation: rotate 1s linear infinite;
  font-size: 60px;
  color: #00acdf;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }

  to {
    transform: rotate(360deg);
  }
}

.playerLeftSlider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 250px;
  background: red;
  z-index: 99;
}

.playerLeftSlider_holder {
  width: 100%;
  height: 100%;
  position: relative;
}

.banner-add-to-home-screen {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: linear-gradient(#00acdf, #00679b);
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  border: 2px solid #fff;
}

.banner-add-to-home-screen button {
  width: 300px;
  height: 50px;
  border: 0;
  background: linear-gradient(#00acdf, #00679b);
  box-shadow: 0 0 5px 0 rgba(0, 0, 0, 0.3);

  color: #fff;
  font-weight: 700;
  border-radius: 8px;
  margin: 0 20px;
}

.spinner-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: linear-gradient(#1D2642, #3A506B, #1D2642);
  opacity: 0.96;
  z-index: 999;
}

.spinner-wrapper p {
  margin-top: 30px;
  color: #fff;
  text-transform: uppercase;
  letter-spacing: 4px;
  font-size: 26px;
  font-weight: 300;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(360deg);
  }
}

.cursor {
  cursor: pointer !important;
}

.transparent {
  background: transparent !important;
}

.yellow {
  color: #e2ac37 !important;
}

.ligth_blue {
  color: #00acdf !important;
}

.red {
  color: rgb(224, 46, 46) !important;
  margin-right: 10px;
}

@keyframes rotate {
  from {
    -webkit-transform: rotate(0);
    transform: rotate(0);
  }

  to {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
