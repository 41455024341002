.footer {
  width: 100%;
  height: 100px;
  background: #323e43;
  z-index: 2;
  position: relative;
  font-size: 13px;
  color: #c1c1c1;
  display: flex;
  align-items: center;
}

.footer_row {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.footer_row p {
  display: flex;
  flex-direction: column;
  font-weight: normal;
}

.footer_row img {
  width: 100px;
}

@media (max-width: 500px) {
  .footer {
    font-size: 11px;
  }

  .copyright {
    font-size: 13px;
  }

  .footer_row img {
    width: 70px;
  }
}
