.row_between {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #e5e5e5;
}

.row_between:last-child {
  border-bottom: 0;
}

.row_between p {
  font-size: 16px;
  padding: 25px 0;
  color: #2c3e50;
}

.row_between span {
  cursor: pointer;
  width: 26px;
  height: 26px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  border: 1px solid #2c3e50;
  color: #2c3e50;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

.row_between span.active_check {
  background: #0095da;
  color: #fff;
  border-color: transparent;
}

.settings_btn {
  min-width: 215px;
  height: 40px;
  font-size: 18px;
  border-radius: 4px;
  padding: 7px 20px 9px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid #e5e5e5;
  color: #323e43;
  background: #fff;
}

.settings_btn:hover {
  color: #00acdf;
}

/* Account */

.account h5 {
  width: 300px;
  font-size: 18px;
  font-weight: 500;
  padding-bottom: 10px;
  color: #323e43;
}

.account .email_address {
  border-bottom: 1px solid #d1d1d1;
}

.account p {
  margin-bottom: 30px;
  color: #323e43;
  font-size: 14px;
  margin-top: 10px;
}

.account button {
  background: linear-gradient(#00acdf, #00679b);
  color: #fff;
}

.account button:hover {
  color: #fff;
  opacity: 0.7;
}

/* Pin Code */

.pincode .input_holder {
  position: relative;
}

.pincode .input_holder svg {
  position: absolute;
  right: 0;
  top: 25px;
  color: #d1d1d1;
  cursor: pointer;
}

.pincode input {
  width: 100%;
  font-size: 16px;
  font-weight: lighter;
  color: #323e43;
  padding: 18px 0;
  border: none;
  border-bottom: 1px solid #d1d1d1;
  background: none;
  height: 60px;
}

.pincode input::placeholder {
  color: #d1d1d1;
}

.pincode p {
  font-size: 16px;
  margin: 30px 0;
  color: #323e43;
}

.pincode_popup {
  position: absolute;
  top: 0;
  left: 50%;
  transform: translateX(-50%);
  width: 300px;
  padding: 20px;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #fff;
}

.pincode_popup p {
  margin: 0;
  padding: 0;
  color: #00acdf;
  font-size: 18px;
  font-weight: bold;
}

.add_pin_code {
  display: flex;
  align-items: center;
  margin-top: 30px;
}

.add_pin_code button {
  width: 200px;
  height: 45px;
  border-radius: 8px;
  box-shadow: 0 0 20px 5px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
  border: 1px solid #e5e5e5;
  font-size: 16px;
  font-weight: 700;
  color: #fff;
  margin-right: 20px;
}

.add_pin_code button.add {
  background: linear-gradient(#00acdf, #00679b);
}

.add_pin_code button.remove {
  background: linear-gradient(#e63242, #ad0d1b);
}

.add_pin_code button:hover {
  opacity: 0.7;
}

/* Help */

.help a {
  color: #323e43;
  font-size: 16px;
  padding: 20px 0 50px 0;
  display: block;
  width: 250px;
}

.help a:last-child {
  padding-bottom: 0;
}

@media (max-width: 800px) {
  .settings_content {
    padding: 20px 20px 20px 50px;
  }

  .row_between p {
    font-size: 14px;
    padding: 15px 0;
  }
}

@media (max-width: 600px) {
  .settings_content h3 {
    font-size: 20px;
  }

  .row_between span {
    width: 15px;
    height: 15px;
  }

  .row_between span > svg {
    font-size: 10px;
  }

  .help a {
    font-size: 12px !important;
    padding: 0;
    padding-bottom: 20px;
  }

  .settings_content {
    padding: 20px;
  }

  .row_between p {
    font-size: 12px;
  }

  .row_between p:nth-child(2) {
    text-align: right;
  }

  .settings_content::before {
    width: 20px;
  }

  .pincode input {
    padding: 10px 0;
    height: 60px;
  }

  .pincode input::placeholder {
    font-size: 16px;
  }

  .pincode p {
    font-size: 12px !important;
    margin: 20px 0;
  }

  .account h5 {
    width: unset;
    margin-top: 30px;
    font-size: 14px;
  }

  .account p {
    font-size: 12px;
  }

  .settings_btn {
    min-width: unset;
    width: 215px;
    font-size: 14px;
    padding: 0px 20px;
  }
}

@media (max-width: 400px) {
  .settings_content {
    padding: 10px 10px 10px 20px;
  }

  .row_between p {
    font-size: 10px;
  }

  .pincode p {
    font-size: 10px !important;
  }

  .pincode input {
    height: 40px;
    padding: 5px 0;
  }

  .pincode input::placeholder {
    font-size: 12px;
  }
}
