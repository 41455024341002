.left_slider {
  position: fixed;
  top: 0;
  left: 0;
  bottom: 0;
  width: 220px;
  overflow: hidden;
  padding: 20px 15px;
  z-index: 99;
  transition: all 0.5s ease-in-out;
  transform: translate3d(-250px, 0, 0);
}

.left_slider_holder {
  width: 100%;
  height: 100%;
  position: relative;
  transition: all 0.1s ease-in-out;
}

.left_slider .arrow {
  position: absolute;
  left: 0;
  right: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 24px;
  color: rgb(18, 166, 211);
  cursor: pointer;
  z-index: 10;
}

.left_slider .arrow.top:hover img {
  opacity: 1;
}

.left_slider .arrow.bottom:hover img {
  opacity: 1;
}

.left_slider .arrow.top {
  top: 0;
}

.left_slider .arrow.top > img {
  transform: rotate(90deg);
  width: 15px;
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
}

.left_slider .channel_name {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  padding: 0 5px;
  transform: translateY(-50%);
  font-size: 18px;
  font-weight: 600;
  text-align: center;
}

.left_slider .arrow.bottom > img {
  transform: rotate(-90deg);
  width: 15px;
  opacity: 0.6;
  transition: all 0.5s ease-in-out;
}

.left_slider .arrow.bottom {
  bottom: 0;
}

.left_slider_holder .item {
  width: 100%;
  height: 120px;
  margin-bottom: 20px;
  position: relative;
  background: hsla(0, 0%, 100%, 0.6);
  box-shadow: 0 0 12px 5px rgba(0, 0, 0, 0.1);
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 3px solid transparent;
  border-radius: 8px;
}

.left_slider_holder .item .right {
  position: absolute;
  top: 5px;
  right: 5px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.left_slider_holder .item .right svg {
  margin-bottom: 7px;
  font-size: 18px;
  color: #333;
}

.left_slider_holder .item img {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  max-width: 70%;
  max-height: 70%;
}

.left_slider_holder .item:hover {
  transform: scale(1.1);
}

.left_slider_holder .item.active {
  border: 3px solid #1ca3ea;
}

@media (max-width: 1350px) {
  .left_slider {
    width: 200px;
  }
}

@media (max-width: 1100px) {
  .left_slider {
    width: 180px;
  }
}

.pincode_wrap {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
