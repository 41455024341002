.radio_player {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99;
  background: #fff;
  animation: opacity 0.5s forwards;
  opacity: 0;
}

.hidden_player {
  display: none !important;
}

@keyframes opacity {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.radio_player_holder {
  width: 100%;
  height: 100%;
  position: relative;
}

.radio_player_holder::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: linear-gradient(360deg, transparent, rgba(0, 0, 0, 0.8));
  height: 130px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  transform: translateY(-130px);
}

.radio_player_holder::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, transparent, rgba(0, 0, 0, 0.8));
  height: 130px;
  z-index: 1;
  transition: all 0.5s ease-in-out;
  transform: translateY(130px);
}

.close-player-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: url(../../images/player_icons/closeWhite.png);
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 9;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
  transform: translateX(200px);
}

.close-player-icon:hover {
  position: absolute;
  top: 20px;
  right: 20px;
  width: 60px;
  height: 60px;
  background: url(../../images/player_icons/closeBlue.png);
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 9;
  cursor: pointer;
}
/* Center info */

.radio_player_center_info {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
}

.radio_player_center_info_img {
  width: 150px;
  height: 90px;
  background: #fff;
  box-shadow: 0 0 25px 3px rgba(0, 0, 0, 0.2);
  background-size: 60% !important;
  background-repeat: no-repeat !important;
  background-position: center center !important;
}

.radio_player_center_info h5 {
  font-size: 20px;
  font-weight: 600;
  color: #0095da;
  margin: 20px 0;
}

.radio_player_center_info p {
  font-size: 26px;
  font-weight: 300;
  color: #000;
}

/* Controls */

.radio_player .play {
  position: fixed;
  left: 50%;
  bottom: 25px;
  width: 60px;
  cursor: pointer;
  margin: 0;
  z-index: 20;
  opacity: 0.8;
  transition: all 0.5s ease-in-out;
  transform: translate(-50%, 300px);
}

.radio_player .play:hover {
  opacity: 1;
}

.radio_player_controls {
  position: absolute;
  bottom: 20px;
  left: 240px;
  right: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 20;
  transition: all 0.5s ease-in-out;
  transform: translateY(300px);
}

.radio_player_controls .volume_holder {
  position: relative;
  width: 25px;
  height: 25px;
  cursor: pointer;
}

.radio_player_controls .volume_holder img {
  width: 25px;
  height: 25px;
}

.radio_player_controls .volume {
  width: 25px;
  cursor: pointer;
}

.radio_player_controls .volume_holder:hover .range_holder {
  opacity: 1 !important;
}

.range_holder {
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 4px;
  width: 100px;
  transform: rotate(-90deg);
  position: absolute;
  left: -40px;
  bottom: 80px;
  opacity: 0;
}

.range_wrap {
  width: 100%;
  height: 4px;
  background: #d3d3d3;
  outline: none;
  border-radius: 2px;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

.radio_player_controls input {
  cursor: pointer;
  position: absolute;
  -webkit-appearance: none;
  width: 100%;
  height: 100%;
  background: #d3d3d3;
  outline: none;
  border-radius: 2px;
}

.radio_player_controls input::-webkit-slider-thumb {
  -webkit-appearance: none;
  appearance: none;
  width: 16px;
  height: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

.radio_player_controls input::-moz-range-thumb {
  width: 16px;
  height: 16px;
  background: #fff;
  cursor: pointer;
  border-radius: 50%;
  box-shadow: 1px 1px 5px 0 rgba(0, 0, 0, 0.4);
}

/* Mini bottom info */
.radio_player_mini_info {
  display: flex;
  align-items: center;
}

.radio_player_mini_info_img {
  width: 140px;
  height: 75px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #e4e4e5;
  border: 1px solid #cfd0d0;
  position: relative;
  margin-right: 20px;
}

.radio_player_mini_info_img > img {
  max-width: 80%;
  max-height: 80%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  margin: auto;
}

.radio_player_mini_info h3 {
  color: #fff;
  text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.8);
  font-weight: 300;
  font-size: 26px;
}

@media (max-width: 1350px) {
  .radio_player_controls {
    left: 220px;
  }
}

@media (max-width: 1200px) {
  .radio_player .play {
    left: 60%;
  }
}

/* On hover */
.radio_player:hover .radio_player_holder::before {
  transform: translateY(0);
}

.radio_player:hover .radio_player_holder::after {
  transform: translateY(0);
}

.radio_player:hover .close-player-icon {
  transform: translateX(0);
}

.radio_player:hover .left_slider {
  transform: translateX(0);
}

.radio_player:hover .radio_player_controls {
  transform: translateY(0);
}

.radio_player:hover .play {
  transform: translate(-50%, 0);
}

/* Fadeout */
.radio_player.fadeout .radio_player_holder::before {
  transform: translateY(-130px);
}

.radio_player.fadeout .radio_player_holder::after {
  transform: translateY(130px);
}

.radio_player.fadeout .close-player-icon {
  transform: translateX(200px);
}

.radio_player.fadeout .left_slider {
  transform: translateX(-300px);
}

.radio_player.fadeout .radio_player_controls {
  transform: translateY(300px);
}

.radio_player.fadeout .play {
  transform: translate(-50%, 300px);
}
