.navbar_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100px;
  background: #323e43;
  display: flex;
  align-items: center;
  z-index: 99;
}

.sticky {
  transform: translateY(-100px) !important;
}

.navbar {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
}

.fa-bars {
  display: none;
}

.navbar::after {
  content: "";
  position: absolute;
  bottom: -4px;
  left: 0;
  right: 0;
  height: 4px;
  background: linear-gradient(to right, #139bd7, #61c9d9);
}

.navbar_holder {
  width: 100%;
  height: 100%;
  display: grid;
  grid-template-columns: 200px 1fr 200px;
  align-items: center;
  position: relative;
}

.nav_logo_holder {
  width: 170px;
}

.nav_logo {
  width: 100%;
}

.navbar_items {
  display: grid;
  justify-content: center;
  grid-template-columns: repeat(6, 180px);
  justify-items: center;
}

.navbar_item_active span {
  background: #139bd7 !important;
}

.navbar_item span .news_icon {
  width: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.navbar_item {
  color: #fff;
  font-size: 14px;
  font-weight: 400;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.navbar_item span {
  width: 45px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background: #656e72;
  font-size: 24px;
  margin-bottom: 10px;
  transition: all 0.3s ease-in-out;
  position: relative;
}

.navbar_item:hover > span {
  background: #a4afb3;
}

.navbar_item span img {
  width: 27px;
}

.search {
  justify-self: end;
}

.search_icon {
  color: #fff;
  font-size: 34px;
  margin-top: 5px;
  cursor: pointer;
}

.search_holder {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 250px;
}

.search_holder input {
  border: 0;
  border-bottom: 1px solid #fff;
  background: transparent;
  font-size: 16px;
  padding: 5px 0;
  width: 100%;
  animation: width 0.6s forwards;
  transition: all 0.3s ease-in-out;
  color: #fff;
  margin-left: 7px;
}

@keyframes width {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

.search_holder .fa-times {
  color: #fff;
  font-size: 20px;
  cursor: pointer;
}

@media (max-width: 1600px) {
  .navbar_items {
    grid-template-columns: repeat(6, 130px);
  }
}

@media (max-width: 1400px) {
  .navbar_holder {
    grid-template-columns: 1fr 750px 1fr;
  }

  .nav_logo_holder {
    width: 120px;
  }

  .navbar_items {
    grid-template-columns: repeat(6, 130px);
  }
}

@media (max-width: 1130px) {
  .navbar_holder {
    grid-template-columns: 1fr 500px 1fr;
  }

  .navbar_items {
    grid-template-columns: repeat(6, 100px);
  }
}

@media (max-width: 1150px) {
  .search_holder {
    width: 200px;
  }

  .search_icon {
    font-size: 24px;
  }

  .search_holder input {
    font-size: 14px;
  }

  .search_holder .fa-times {
    font-size: 16px;
  }

  .navbar_item span {
    width: 40px;
    height: 40px;
    font-size: 20px;
    margin-bottom: 7px;
  }

  .navbar_item {
    font-size: 12px;
  }

  .navbar_item span img {
    width: 22px;
  }
}

@media (max-width: 1000px) {
  .navbar_holder {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .search {
    display: none;
  }

  .search_holder.hidden {
    display: flex;
    position: fixed;
    left: 20px;
    bottom: 20px;
    z-index: 9999;
    width: 250px;
  }

  .fa-bars {
    display: inline;
    position: absolute;
    right: 0;
    color: #fff;
    font-size: 26px;
    cursor: pointer;
  }

  .navbar_items {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    width: 300px;
    background: #323e43;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    z-index: 9999;
    box-shadow: 0 0 20px 0 #000;
    overflow: hidden;
    padding: 20px 0;
    transition: all 0.3s ease-in-out;
    transform: translateX(-350px);
  }

  .active_navbar_items {
    transform: translateX(0) !important;
  }

  .navbar_item {
    flex-direction: row;
    width: 100%;
    margin-bottom: 10px;
    font-size: 18px;
    padding: 10px 20px;
  }

  .navbar_item > span {
    margin: 0 10px 0 0 !important;
  }

  .navbar_item_active {
    background: #139bd7 !important;
  }

  .navbar_item_active span {
    background: #323e43 !important;
  }
}

@media (max-width: 500px) {
  .navbar_items {
    width: 250px;
  }

  .navbar_item {
    font-size: 14px;
  }

  .navbar_item > span {
    width: 35px;
    height: 35px;
    font-size: 16px;
  }

  .search_holder.hidden {
    width: 200px;
  }
}
