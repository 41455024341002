.channel_grid_item.grid_item .grid_item_top {
  background-size: contain !important;
  background-repeat: no-repeat;
  background-position: center center;
  background: url(../../images/mini_logo.png);
}

.channel_grid_item.grid_item .grid_item_bottom.channel_grid {
  flex-direction: row;
  align-items: center;
  justify-content: space-between !important;
}

.channel_grid_item.grid_item .stream_icon {
  position: static !important;
}

.channel_grid_item.grid_item .grid_item_bottom.channel_grid .epg_title {
  font-size: 16px;
}

.channel_grid_item .grid_item_top {
  position: relative;
  box-shadow: 0px 1px 0px 0px rgba(0, 0, 0, 0.1);
}

.channel_grid_item .fa-star-fav {
  position: absolute;
  bottom: 10px;
  right: 10px;
  font-size: 30px;
  color: rgb(226, 172, 55);
}

.channel_grid_item .lock {
  position: absolute;
  bottom: 10px;
  left: 10px;
  font-size: 24px;
  color: #333;
}
