.login_wrapper {
  position: relative;
  width: 100%;
  height: 100vh;
  background: url(../images/login.jpg);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 0 50px;
}

.login_wrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.8);
}

.login_content {
  background: linear-gradient(
    153deg,
    hsla(0, 0%, 100%, 0.05),
    hsla(0, 0%, 100%, 0.01) 35%,
    rgba(0, 0, 0, 0.01) 60%,
    hsla(0, 0%, 100%, 0.05)
  );
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  width: 400px;
  height: 580px;
  position: relative;
  box-shadow: 0 0 10px 0 rgba(255, 255, 255, 0.25);
  z-index: 99;
}

.login_content img {
  width: 200px;
  position: absolute;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);
}

.login_content form {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 50px 20px;
}

.login_content form label {
  width: 100%;
  display: block;
  margin-bottom: 5px;
  color: #a3a3a3;
  font-size: 16px;
}

.login_content form input:not([type="submit"]) {
  width: 100%;
  background: transparent;
  border: 0;
  border-bottom: 1px solid #a3a3a3;
  color: #fff;
  padding: 5px 0;
  margin-bottom: 30px;
  font-size: 16px;
  padding-right: 30px;
}

.login_content .input_holder {
  width: 100%;
  position: relative;
}

.login_content .input_holder svg {
  position: absolute;
  top: 4px;
  right: 0;
  color: #a3a3a3;
  cursor: pointer;
}

.login_content .dont_acc {
  width: 100%;
  display: flex;
  align-items: center;
  color: #a3a3a3;
  font-size: 16px;
  font-weight: 400;
  margin: -20px 0 30px 0;
}

.login_content .dont_acc a {
  color: #0095da;
  font-size: 16px;
  margin-left: 5px;
  font-weight: 400;
  transition: all 0.3s ease-in-out;
  z-index: 5;
}

.login_content .dont_acc a:hover {
  opacity: 0.8;
}

.login_content form input[type="submit"] {
  width: 100%;
  height: 50px;
  border-radius: 4px;
  background: #0095da;
  font-size: 18px;
  font-weight: 400;
  color: #fff;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
  border: 0;
  letter-spacing: 1px;
}

.login_content form input[type="submit"]:hover {
  background: #fff;
  color: #333;
}

.login_content .custom_select {
  width: 100%;
  height: 40px;
  position: relative;
  margin-bottom: 30px;
}

.login_content .custom_select .capital {
  width: 100%;
  height: 40px;
  border: 1px solid #9b9b9b;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 15px;
  border-radius: 4px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
}

.login_content .custom_select .capital svg {
  transition: all 0.3s ease-in-out;
}

.login_content .custom_select .capital.rotate svg {
  transform: rotate(-180deg);
}

.login_content .custom_select .options {
  position: absolute;
  left: 0;
  right: 0;
  top: 37px;
  border: 1px solid #9b9b9b;
  background: #000;
  max-height: 210px;
  border-radius: 0 0 4px 4px;
  overflow-y: scroll;
  z-index: 5;
}

.login_content .custom_select .options::-webkit-scrollbar {
  width: 0;
}

.login_content .custom_select .options p {
  padding: 10px 15px;
  color: #9b9b9b;
  font-size: 16px;
  font-weight: 400;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.login_content .custom_select .options p:hover {
  color: #fff;
}

.login_content .error-message {
  margin: -20px 0 20px 0;
}
