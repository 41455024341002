.categoriesPopup_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
}

.categoriesPopup_holder {
  width: 400px;
  height: 500px;
  background: #fff;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  overflow: hidden;
  border-radius: 4px;
  position: relative;
}

.categoriesPopup_holder .item {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 60px;
  border-bottom: 1px solid #eee;
  color: rgb(126, 126, 126);
  font-size: 18px;
  font-weight: 800;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.all_locked {
  position: absolute;
  top: 50%;
  left: 0;
  right: 0;
  transform: translateY(-50%);
  margin: 0 !important;
  font-size: 24px !important;
  text-align: center;
}

.categoriesPopup_holder .item:hover {
  background: linear-gradient(#00acdf, #00679b);
  color: #fff;
}

/* Enter Pin Popup */
.enter_pin_wrapper {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.1);
  z-index: 9999;
}

.enter_pin_holder {
  width: 450px;
  height: 300px;
  background: #fff;
  box-shadow: 3px 3px 20px rgba(0, 0, 0, 0.3);
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
}

.enter_pin_holder h2 {
  margin-bottom: 20px;
  font-size: 24px;
  font-weight: 500;
  color: #555;
}

.enter_pin_holder > div {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.enter_pin_holder > div input {
  width: 100%;
  height: unset;
  padding: 0;
  margin: 0;
  bottom: 0;
  padding: 5px;
  text-align: center;
}

.enter_pin_holder > div input::placeholder {
  color: #00acdf;
  padding: 0;
  margin: 0;
  font-weight: bold;
}

.enter_pin_holder p {
  font-size: 16px;
  color: #c00;
  margin: 0;
  padding: 0;
}

.enter_pin_holder button {
  padding: 15px 30px;
  border-radius: 4px;
  background: linear-gradient(#00acdf, #00679b);
  border: 0;
  color: #fff;
  font-weight: bold;
  font-size: 14px;
}

.enter_pin_holder button:hover {
  opacity: 0.8;
}

.pincode-input-text {
  border: 0 !important;
  border-right: 1px solid #00acdf !important;
  font-weight: bold !important;
  font-size: 30px !important;
  color: #00acdf !important;
}

.pincode-input-text:last-child {
  border-right: 0 !important;
}

@media (max-width: 1100px) {
  .categoriesPopup_wrapper {
    position: fixed;
  }
}

@media (max-width: 500px) {
  .categoriesPopup_holder {
    width: 70%;
    height: 50%;
  }

  .categoriesPopup_holder .item {
    height: 40px;
    font-size: 16px;
  }
}
