.nowontvslider {
  height: 360px;
  padding: 0 60px 30px 60px;
  background: hsla(0, 0%, 100%, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  z-index: 99;
}

.nowontvslider .row {
  margin-top: 15px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-left: 8px;
  padding-right: 2px;
}

.nowontvslider .row a {
  display: flex;
  align-items: center;
  font-size: 30px;
  color: #000;
  transition: all 0.3s ease-in-out;
  font-weight: lighter;
}

.nowontvslider .row a svg {
  font-size: 24px;
  margin-top: 5px;
  transition: all 0.3s ease-in-out;
  color: #000;
}

.nowontvslider .row a:hover {
  color: #1ca4ea;
}

.nowontvslider .row a:hover svg {
  transform: translateX(5px);
  color: #1ca4ea;
}

.nowontvslider .nowontvslider_holder {
  display: grid;
  grid-auto-rows: 230px;
  margin-top: auto;
}

/* Media */
@media (max-width: 1700px) {
  .nowontvslider {
    height: 330px;
  }

  .nowontvslider .nowontvslider_holder {
    grid-auto-rows: 200px;
  }
}

@media (max-width: 1500px) {
  .nowontvslider {
    padding: 0 30px 30px 30px;
    height: 270px;
  }

  .nowontvslider .nowontvslider_holder {
    grid-auto-rows: 170px;
  }

  .nowontvslider .row {
    padding: 0 0 0 10px;
  }

  .nowontvslider .row .title {
    font-size: 28px;
  }

  .nowontvslider .row a {
    font-size: 21px;
  }

  .nowontvslider .row a svg {
    font-size: 16px;
    margin: 0;
  }
}

@media (max-width: 1300px) {
  .nowontvslider {
    padding: 0 30px 30px 30px;
    height: 250px;
  }

  .nowontvslider .nowontvslider_holder {
    grid-auto-rows: 150px;
  }
}

@media (max-width: 1024px) {
  .nowontvslider {
    height: 300px;
  }

  .nowontvslider .row {
    padding: 0;
  }

  .nowontvslider .nowontvslider_holder {
    grid-auto-rows: 200px;
  }

  .nowontvslider .nowontvslider_holder .grid_items_holder {
    grid-auto-rows: 200px;
    grid-template-rows: 200px;
    margin: 0;
  }

  .nowontvslider .nowontvslider_holder .grid_item .grid_item_top {
    height: 110px;
  }

  .nowontvslider .nowontvslider_holder .grid_item .grid_item_bottom {
    height: 90px;
  }
}
