.now_on_tv_screen .channels_track_list_icon {
  width: 0;
  height: 0;
}

.now_on_tv_screen .rotate {
  transform: rotate(180deg);
}

.now_on_tv_screen .row {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 20px 10px;
}

.now_on_tv_screen .row > div {
  display: flex;
  align-items: center;
}

.now_on_tv_no_items {
  position: absolute;
  bottom: 100px;
  left: 0;
  right: 0;
  top: 235px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #777;
  font-size: 26px !important;
}

.now_on_tv_no_items p {
}

.now_on_tv_no_items svg {
  font-size: 3em;
  fill: #777;
}
