.categories_wrapper {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 99;
  transform: translateX(-100%);
  transition: all 0.4s ease-in-out;
}

.categories_wrapper.slide {
  transition: all 0.4s ease-in-out;
  transform: translateX(0) !important;
}

.categories_holder {
  width: 450px;
  height: 100%;
  position: relative;
  background: #202020;
}

.categories_wrapper .close-icon {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background: url(../../images/player_icons/closeWhite.png);
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 999;
  cursor: pointer;
  -webkit-transition: all 0.5s ease-in-out;
  -o-transition: all 0.5s ease-in-out;
  transition: all 0.5s ease-in-out;
}

.categories_wrapper .close-icon:hover {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 50px;
  height: 50px;
  background: url(../../images/player_icons/closeBlue.png);
  background-size: 50% 50%;
  background-position: center center;
  background-repeat: no-repeat;
  z-index: 99;
  cursor: pointer;
}

.categories_content {
  position: absolute;
  top: 70px;
  bottom: 20px;
  right: 0;
  left: 0;
  padding: 0 20px;
  overflow-y: scroll;
  margin-right: 10px;
}

.categories_content::-webkit-scrollbar {
  width: 10px;
}

/* Track */
.categories_content::-webkit-scrollbar-track {
  background: #333;
  border-radius: 30px;
}

/* Handle */
.categories_content::-webkit-scrollbar-thumb {
  background: #141414;
  border-radius: 30px;
}

/* Handle on hover */
.categories_content::-webkit-scrollbar-thumb:hover {
  background: #222;
}

.categories_item {
  display: flex;
  align-items: center;
  padding: 15px 0;
  cursor: pointer;
  border-bottom: 1px solid #4d4d4d;
  transition: all 0.3s ease-in-out;
}

.categories_item.active p {
  color: #00acdf;
}

.categories_item.active .img_holder {
  background: #00acdf;
}

.categories_item svg {
  color: #000;
  font-size: 26px;
}

.categories_item:last-child {
  border-bottom: 0;
}

.categories_item:hover {
  background: #202020;
}

.categories_item:hover p {
  color: #00acdf;
}

.categories_item:hover .img_holder {
  background: #00acdf;
}

.categories_item .img_holder {
  width: 40px;
  height: 40px;
  background: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.3s ease-in-out;
}

.categories_item img {
  width: 30px;
}

.categories_item > p {
  color: #f8f8f8;
  font-weight: lighter;
  letter-spacing: 1px;
  font-size: 18px;
  margin-left: 20px;
  text-transform: uppercase;
  transition: all 0.3s ease-in-out;
}

@media (max-width: 1550px) {
  .categories_holder {
    width: 400px;
  }
}

@media (max-width: 1000px) {
  .categories_holder {
    width: 350px;
  }

  .categories_wrapper .close-icon {
    font-size: 24px;
  }

  .categories_item > img {
    width: 30px;
  }

  .categories_item > p {
    font-size: 14px;
  }
}

@media (max-width: 500px) {
  .categories_holder {
    width: 280px;
  }

  .categories_wrapper .close-icon {
    font-size: 20px;
  }

  .categories_item > img {
    width: 25px;
  }

  .categories_item > p {
    font-size: 12px;
  }
}
